<div>
  <div fxLayout="row wrap" class="border-b-1">
    <ul class="top-nav m-0 p-0" fxFlex>
      <li><a class="text-bold active" (click)="getList()">Exit Customers</a> </li>
    </ul>

    <div fxFlex="280px" fxLayout="row wrap" fxLayoutAlign="end center">
      <mat-form-field class="w-100-p country-p" appearance='outline'>
        <mat-select [(ngModel)]="country_id" (selectionChange)="countryChanged()">
          <mat-option *ngFor="let item of countries" [value]="item.value">
            {{item.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex="392px" fxLayout="row wrap" fxLayoutAlign="end center">
      <mat-form-field class="w-280 mx-20 pt-4" appearance="outline" [matMenuTriggerFor]="menu">
        <mat-label>Select exit date</mat-label>
        <mat-date-range-input [rangePicker]="rangePicker" [formGroup]="dateRangeForm">
          <input matStartDate placeholder="Start date" readonly formControlName="start_date">
          <input matEndDate placeholder="End date" readonly formControlName="end_date">
        </mat-date-range-input>
      </mat-form-field>

      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="setDate('this_week')" [ngClass]="{'primary': (dateActive == 'this_week')}">This
          Week</button>
        <button mat-menu-item (click)="setDate('this_month')" [ngClass]="{'primary': (dateActive == 'this_month')}">This
          Month</button>
        <button mat-menu-item (click)="setDate('last_month')" [ngClass]="{'primary': (dateActive == 'last_month')}">Last
          Month</button>
        <button mat-menu-item (click)="rangePicker.open();dateActive = 'custom';"
          [ngClass]="{'primary': dateActive == 'custom'}">Custom Range</button>
        <button mat-menu-item (click)="setDate('clear')" [ngClass]="{'primary': dateActive == 'clear'}">Clear</button>
        <!-- <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle> -->
        <mat-date-range-picker #rangePicker>
          <mat-date-range-picker-actions>
            <button mat-button matDateRangePickerCancel>Cancel</button>
            <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
          </mat-date-range-picker-actions>
        </mat-date-range-picker>
      </mat-menu>
      <button mat-icon-button class="ml-4 mr-20" matTooltip="Refresh" (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>

  <!-- <ul fxLayout="row" fxLayoutAlign="start center" class="top-nav m-0 p-0">
          <li><a class="text-bold text-capitalize active">All</a></li>
        </ul> -->
  <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">
    <div class="search-wrapper" fxFlex="30">
      <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
        [readOnly]="dataSource.length === 0" [(ngModel)]="searchValue" placeholder="Company Name">
      <mat-icon class="search_icon">search</mat-icon>
      <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
        close
      </mat-icon>
    </div>
    <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
      <div>
        <button mat-flat-button class="primary" (click)="exportExcel()" *ngIf="logginUser?.is_super_user">Export as Excel</button>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center">
        <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
          [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
      </div>
    </div>
  </div>
</div>
<cdk-virtual-scroll-viewport itemSize="50" class="scroll-content-height">
<div class="mx-12 mb-12">
  <ng-container *ngIf="dataSource">
    <mat-table [dataSource]="dataSource" class="table">
      <!-- customername Column -->
      <ng-container matColumnDef="customername">
        <mat-header-cell *matHeaderCellDef fxFlex="230px"> customer name
          <span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="sortColumnData('c.name','asc')">
                <mat-icon>sort</mat-icon>
                <span>Sort Ascending</span>
              </button>
              <button mat-menu-item (click)="sortColumnData('c.name','desc')">
                <mat-icon>sort</mat-icon>
                <span>Sort Descending</span>
              </button>
            </mat-menu>
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="230px"> 
          <a *ngIf="element.is_db_deleted == 0" (click)="navigate(element,'blank')" class="cursor-pointer">{{element.company_name}}</a>
          <span *ngIf="element.is_db_deleted == 1">{{element.company_name}}</span>
        </mat-cell>
      </ng-container>
      <!-- contactname Column -->
      <ng-container matColumnDef="contactname">
        <mat-header-cell *matHeaderCellDef> contact name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.client_info.first_name}}
          {{element.client_info.last_name}} </mat-cell>
      </ng-container>

      <!-- contactemail Column -->
      <ng-container matColumnDef="contactemail">
        <mat-header-cell *matHeaderCellDef fxFlex="200px"> contact email </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="200px" class="lowercase"> {{element.client_info.email}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="region">
        <mat-header-cell *matHeaderCellDef> region
          <span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="sortColumnData('c.region','asc')">
                <mat-icon>sort</mat-icon>
                <span>Sort Ascending</span>
              </button>
              <button mat-menu-item (click)="sortColumnData('c.region','desc')">
                <mat-icon>sort</mat-icon>
                <span>Sort Descending</span>
              </button>
            </mat-menu>
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span *ngIf="element.region != 'usa'">{{element.region | titlecase}}</span>
          <span *ngIf="element.region == 'usa'">{{element.region | uppercase }}</span>
        </mat-cell>
      </ng-container>

      <!-- status Column -->
      <ng-container matColumnDef="existed_date">
        <mat-header-cell *matHeaderCellDef> exit date
          <span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="sortColumnData('c.exit_date','asc')">
                <mat-icon>sort</mat-icon>
                <span>Sort Ascending</span>
              </button>
              <button mat-menu-item (click)="sortColumnData('c.exit_date','desc')">
                <mat-icon>sort</mat-icon>
                <span>Sort Descending</span>
              </button>
            </mat-menu>
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.company_updated_date | date:'MM/dd/yyyy'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="onboarded_date">
        <mat-header-cell *matHeaderCellDef> Onboarded On
          <span>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="sortColumnData('csd.subscr_start_date','asc')">
                <mat-icon>sort</mat-icon>
                <span>Sort Ascending</span>
              </button>
              <button mat-menu-item (click)="sortColumnData('csd.subscr_start_date','desc')">
                <mat-icon>sort</mat-icon>
                <span>Sort Descending</span>
              </button>
            </mat-menu>
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span *ngIf="element.onboarded_date">{{element.onboarded_date == '-' ? '-' : element.onboarded_date | date:"MM/dd/yyyy" }}</span>
          <span *ngIf="!element.onboarded_date">-</span>
        </mat-cell>
      </ng-container>

      <!-- subdomain Column -->
      <ng-container matColumnDef="hire">
        <mat-header-cell *matHeaderCellDef fxFlex="230px"> hire </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="230px"> U ({{element.hire_users}})
          | M ({{element.mass_mails}}) | C ({{element.contact_search}}) </mat-cell>
      </ng-container>

      <!-- region Column -->
      <ng-container matColumnDef="crm">
        <mat-header-cell *matHeaderCellDef> hr </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.crm_users}} </mat-cell>
      </ng-container>

      <!-- region Column -->
      <ng-container matColumnDef="hr">
        <mat-header-cell *matHeaderCellDef> crm </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.hr_users}} </mat-cell>
      </ng-container>

      <!-- region Column -->
      <ng-container matColumnDef="monthly_pay">
        <mat-header-cell *matHeaderCellDef> Monthly Paid </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.total_cost | currency}} </mat-cell>
      </ng-container>

      <!-- subscriptionenddate Column -->
      <ng-container matColumnDef="last_paid">
        <mat-header-cell *matHeaderCellDef> last paid </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.last_paid | date:"MM/dd/yyyy"}} </mat-cell>
      </ng-container>

      <!-- status Column -->
      <ng-container matColumnDef="due_payments">
        <mat-header-cell *matHeaderCellDef> due payments </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.is_due }} </mat-cell>
      </ng-container>

      <!-- status Column -->
      <ng-container matColumnDef="subscription_cycle">
        <mat-header-cell *matHeaderCellDef fxFlex="150px"> subscription cycle </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="150px">
          <span *ngIf="element.subscription_cycle == 1">Monthly</span>
          <span *ngIf="element.subscription_cycle == 3">Quarterly</span>
          <span *ngIf="element.subscription_cycle == 6">Half-Yearly</span>
          <span *ngIf="element.subscription_cycle == 12">Yearly</span>
        </mat-cell>
      </ng-container>

      <!-- Sales Rep Column -->
      <ng-container matColumnDef="sales_user_name">
        <mat-header-cell *matHeaderCellDef fxFlex="230px"> Sales Rep </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="230px"> {{element.sales_user_name ? element.sales_user_name :
          '-' }} </mat-cell>
      </ng-container>

      <!-- Support Executive Column -->
      <ng-container matColumnDef="support_user_name">
        <mat-header-cell *matHeaderCellDef fxFlex="230px"> Support Executive </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="230px"> {{element.support_user_name ?
          element.support_user_name : '-' }} </mat-cell>
      </ng-container>

      <!-- action Column -->
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <!-- <mat-cell *matCellDef="let element">
                  <button mat-icon-button class="accent-fg" matTooltip="Update Sales and Support Exucutive" (click)="edit(element)" *ngIf="permissions['users']">
                    <mat-icon class="s-18">group_add</mat-icon>
                  </button>
                </mat-cell> -->
        <mat-cell *matCellDef="let element">

          <button matBadge="{{element?.comments_cnt}}" matBadgeColor="warn" 
              (click)="viewComment(element)" mat-icon-button  class="teal-fg" matTooltip="Comments"
                    >
                    <mat-icon   (click)="$event.stopPropagation();addComment(element.company_id)"  class="s-18">comments</mat-icon>
             </button>
             
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <div *ngIf="dataSource.data.length == 0" class="text-center">
      <h2 class="heading m-0 py-16 border">No Records Found</h2>
    </div>
  </ng-container>
</div>
</cdk-virtual-scroll-viewport>